import React from 'react';
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';



const Home: React.FC<PageProps> = () => (
  <main>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Nftnaires | Submit Listing Form</title>
    </Helmet>

    <a className="text-white" href="/submit-project">
      Submit Project
    </a>
    <a href="/advertise">Advertise</a>
  </main>
);

export default Home;
